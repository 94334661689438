import React from "react"
import Register from "../components/auth/register"
import PageHeader from "../components/pageheader"
import PageBody from "../components/pagebody"
import Layout from "../components/layout"

const RegisterPage = ({ location }) => {
  return (
    <Layout currPage="register" location={location}>
      <PageHeader pageTitle="Register" />
      <PageBody>
        <Register location={location} />
      </PageBody>
    </Layout>
  )
}

export default RegisterPage
