import React, { useState } from "react"
import { navigate } from "@reach/router"
import useAuth from "../../util/useStrapiAuth"
import { passRedirectThroughAuth } from "../../util/authRedirect"
import { useForm } from "react-hook-form"

const Register = ({ location }) => {
  const { register } = useAuth()
  const {
    register: registerInput,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm()
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })
  const [submitError, setSubmitError] = useState(null)

  const processRegister = (data, e) => {
    e.preventDefault()
    setServerState({ submitting: true })
    register({
      username: data.username,
      email: data.email,
      password: data.password,
    })
      .then(() => {
        setServerState({ submitting: false })
        navigate("/confirmemail", {
          state: {
            redirect: passRedirectThroughAuth(location, "/confirmemail"),
          },
        })
      })
      .catch(e => {
        setServerState({ submitting: false })
        const {
          response: {
            data: { message },
          },
        } = e
        if (message.syscall && message.syscall === "queryMx") {
          setSubmitError("Please provide a real, existing email address")
        } else {
          const [
            {
              messages: [{ message: errormessage }],
            },
          ] = message
          if (errormessage === "Email already taken") {
            setSubmitError("Username is already taken")
          } else setSubmitError(errormessage)
        }
      })
  }

  return (
    <div className="contact">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="contact-form">
              <div className="form">
                <div className="form-group col-md-6">
                  <h3 style={{ textAlign: "center" }}>
                    Please fill in the form to register a new account:
                  </h3>
                  <form onSubmit={handleSubmit(processRegister)}>
                    <div className="form-group">
                      <input
                        {...registerInput("username", {
                          required: "This field is required.",
                        })}
                        className="form-control"
                        type="text"
                        placeholder="Username"
                      />
                      <span className="formFieldError">
                        {errors?.username && errors.username.message}
                      </span>
                    </div>
                    <div className="form-group">
                      <input
                        {...registerInput("email", {
                          required: "This field is required.",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Please provide a valid email.",
                          },
                        })}
                        className="form-control"
                        type="text"
                        placeholder="Email"
                      />
                      <span className="formFieldError">
                        {errors?.email && errors.email.message}
                      </span>
                    </div>
                    <div className="form-group">
                      <input
                        {...registerInput("password", {
                          required: "This field is required.",
                        })}
                        className="form-control"
                        type="password"
                        placeholder="password"
                      />
                      <span className="formFieldError">
                        {errors?.password && errors.password.message}
                      </span>
                    </div>
                    <div className="form-group">
                      <input
                        {...registerInput("password2", {
                          required: "This field is required",
                          validate: val => {
                            if (watch("password", "") !== val) {
                              return "Please make sure the two passwords match."
                            }
                          },
                        })}
                        className="form-control"
                        type="password"
                        placeholder="confirm password"
                      />
                      <span className="formFieldError">
                        {errors?.password2 && errors.password2.message}
                      </span>
                    </div>
                    <div className="btndiv">
                      <button
                        type="submit"
                        disabled={serverState.submitting}
                        className="btn"
                      >
                        {serverState.submitting ? "Registering.." : "Register"}
                      </button>
                    </div>
                    {submitError?.length > 1 && (
                      <p className="formSubmitError">{submitError}</p>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Register
